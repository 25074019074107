@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import '~antd/dist/antd.compact.css'; */
/* @import '~antd/dist/antd.dark.css'; */

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

.rbc-calendar .rbc-event-label {
  font-size: 9px;
  /* transform: scale(0.75); */
}

.rbc-calendar .rbc-event-content {
  font-size: 11px;
  /* transform: scale(0.75); */
}

.rbc-calendar .rbc-event {
  padding: 2px 1px;
}

.ant-layout-header {
  padding: 0 30px;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.rbc-calendar .rbc-time-slot {
  min-height: 30px;
}

.no-padding-collapse {
  background: transparent;
}

.no-padding-collapse>.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: 8px;
}

.no-padding-collapse>.ant-collapse-item>.ant-collapse-header,
.no-padding-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 12px 0px;
}

/* Permission CSS Start*/


.p_dashboard_view_none .p_dashboard_view {
  display: none;
}

/* Company User CSS */
.p_company_user_management_none .p_company_user_management {
  display: none;
}

/* Booking Item CSS */
.p_booking_item_management_none .p_booking_item_management {
  display: none;
}

/* Product Item CSS */
.p_product_item_management_none .p_product_item_management {
  display: none;
}

/* Booking CSS */
.p_booking_management_none .p_booking_management {
  display: none;
}

/* Setting CSS */
.p_setting_management_none .p_setting_management {
  display: none;
}

.p_invoice_management_none .p_invoice_management {
  display: none;
}

.p_report_management_none .p_report_management {
  display: none;
}

.p_website_management_none .p_website_management {
  display: none;
}

.p_payment_management_none .p_payment_management {
  display: none;
}

.p_display_management_none .p_display_management {
  display: none;
}

.p_order_management_none .p_order_management {
  display: none;
}


/* 
._none .p_company_user_view {
  display: none;
}
} */

/* Event CSS */
.send-mail-tooltip .ant-tooltip-inner {
  background: #fff;
  color: #000
}

.ant-menu-submenu-title {
  display: flex;
  padding-left: 0 !important;
  font-family: 'Inter';
}

.ant-menu-submenu-title::after {
  display: flex;
  font-family: 'Inter';
}

.ant-menu-inline .ant-menu-item::before {
  border-right: none;
  position: absolute;
  left: 0;
  background-color: transparent;
  color: #888888;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
  position: absolute;
  left: 0;
  background-color: transparent;
  color: #000
}

.ant-menu-item a {
  color: #888888;
  font-size: 12px;
  font-family: 'Inter';
}

.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title::before {
  color: #888888;
}

/* .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title::after {
  color: #000
}  */
.ant-menu-item-selected a {
  color: #1E73BE;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-submenu-selected {
  color: black;
}

.paddingLeftclassName {
  padding-left: 0px !important;
}

.ant-menu-item.ant-menu-item-only-child:not(.p_dashboard_view) {
  padding-left: 62px !important;
  display: flex;
  align-items: center;
  height: 24px
}

.event-banner-list .ant-row.ant-form-item,
.event-banner-list .ant-upload.ant-upload-select-picture-card,
.event-banner-list .ant-upload-list-picture-card .ant-upload-list-item,
.event-banner-list .ant-upload-list-picture-card-container {
  margin: 0;
  float: none;
  width: 100%;
  /* margin-bottom: 0; */
}

.event-banner-list .ant-upload-picture-card-wrapper {
  display: inherit;
}

.event-banner-list .ant-descriptions-bordered .ant-descriptions-item-label,
.event-banner-list .ant-descriptions-bordered .ant-descriptions-item-content {
  margin: 0;
  padding: 0;
}

.event-banner-list input {
  width: 30;
  border: 0;
}

.event-banner-list input:focus {
  border: 0;
}

.event-banner-list .desc-label {
  text-align: center;
  justify-content: center;
}


.schedule-header .ant-slider-rail,
.schedule-header .ant-slider-track,
.schedule-header .ant-slider-step,
.schedule-header .ant-slider-handle {
  display: none;
}

.schedule-container {
  margin-right: 16px
}

.schedule-header {
  margin: 0 -1px 32px 1px !important;
}

.grid-row {
  padding: 32px 0;
}

.slot::after {
  content: "";
  height: 695px;
  width: 1px;
  background-color: #eee;
  position: absolute;
  top: 225%;
  right: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.icon-button {
  font-size: 10px;
  padding: 2px;
  background-color: white;
  cursor: pointer;
  border-radius: 2px;
}

.resize-box div:last-child {
  z-index: 2
}

.book-schedule-tooltip .ant-tooltip-inner {
  color: #555555;
  padding: 4px 8px;
  font-size: 10px;
  border-radius: 8px;
  min-height: auto;
}

/* .section-price-tag {
  border-radius: 8px;
  background: transparent;
  position: absolute;
  top: -175%;
  left: 50%;
  transform: translateX(-50%);
} */

.section-price-tooltip .ant-tooltip-inner {
  color: #555555;
  font-size: 10px;
  border-radius: 8px;
  min-height: auto;
  background: transparent;
  padding: 0
}

.section-price-tooltip .ant-tooltip-arrow-content {
  background-color: transparent
}

.section-price-tooltip .ant-tag {
  border-radius: 8px
}

/* Event Enrollment List */
.status-button {
  border-radius: 4px;
  margin-right: 32px;
  margin-top: 16px
}

.send-email-modal,
.ant-modal-confirm .ant-modal-body {
  padding: 16px
}

.send-email-modal .ant-modal-title {
  border-bottom: 1px solid #D4D4D4;
  padding-bottom: 4px;
  color: #1E73BE;
  font-weight: bold;
}

.send-email-modal .ant-modal-header {
  border: none;
  padding: 16px
}

.send-email-modal .ant-modal-body {
  padding: 16px
}

.approve-button {
  color: #71C847 !important;
  border-color: #71C847 !important
}

.action-btn {
  border-radius: 4px
}

.event-setting .ant-collapse-borderless {
  background: transparent;
}

.event-setting .ant-collapse-header {
  background: #EEEEEE;
  font-weight: bold;
  padding: 4px 40px 4px 12px !important;
}

.event-setting .ant-collapse-item {
  border: none
}

.event-setting .ant-collapse-content-box {
  padding: 16px 10px 12px !important
}

.event-details .ant-input-number {
  width: 68px
}

.registration-details .ant-collapse-header {
  background: #fff;
  border-bottom: 1px solid #EEEEEE;
  padding: 4px 40px 4px 0 !important
}

.event-enrollment-list .ant-btn-icon-only,
.event-setting .anticon {
  color: #888888
}

.ant-btn-primary {
  box-shadow: none
}

.event-tabs-page {
  margin-top: 16px
}

.event-tabs-page .ant-checkbox-checked .ant-checkbox-inner {
  background: #1E73BE;
}

.QRtitle {
  width: 202px;
  height: 36px;

  /* Header/H5 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Gray/Gray 300 */

  color: #BBBBBB;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Text/White Text */
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

/* Create Event */
.banner-table-header {
  padding: 8px 16px;
  background: #EEEEEE;
  text-align: center
}

.banner-table-body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF
}

.w-100>span {
  width: 100%
}


.banner-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 200px;
  grid-gap: 1px;
  background: #EEEEEE;
  padding: 1px;
  margin-bottom: 24px;
}

.ant-upload.ant-upload-drag {
  padding: 0;
  background: transparent;
  border: none
}

.edit-banner-modal {
  width: 800px !important;
}

.ant-modal-header {
  padding: 16px
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
  background-color: #FFF;
}
